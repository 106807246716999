<template>
  <b-row>
    <b-col cols="12">
      <label>
        <toggle-button sync id="config-enable-sp-initiatied-login"
          :value="value.enable_sp_initiated_login"
          @input="update('enable_sp_initiated_login', $event)"/>
        Enable SP Initiated Login
      </label>
    </b-col>
    <b-col cols="12" v-if="showDisablePasswordRequirement">
      <label>
        <toggle-button sync id="config-disable-password-requirement"
          :value="value.disable_password_requirement"
          @input="update('disable_password_requirement', $event)"/>
        Disable Password Requirement
      </label>
    </b-col>
    <b-col cols="12" v-if="showForceSSOAuthentication">
      <label>
        <toggle-button sync id="config-force-sso-authentication"
          :value="value.force_sso_authentication"
          @input="update('force_sso_authentication', $event)"/>
        Force SSO Authentication
      </label>
    </b-col>
    <b-col cols="12">
      <label>
        <toggle-button sync id="config-enable-signup"
          :value="value.enable_signup"
          @input="update('enable_signup', $event)"/>
        Enable Signup
      </label>
    </b-col>
    <b-col cols="12" v-if="value.enable_signup">
      <b-form-group
        label="Signup URL"
        description="Signup URL for the Partner"
        label-for="config-signup-url">
        <b-form-input
          id="config-signup-url" type="url" placeholder="Enter Signup URL"
          :value="value.signup_url"
          @input="update('signup_url', $event)"/>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'PartnerConfiguration',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showDisablePasswordRequirement() {
      return this.value.enable_sp_initiated_login;
    },
    showForceSSOAuthentication() {
      return this.value.enable_sp_initiated_login;
    },
  },
  methods: {
    update(key, val) {
      const configuration = { ...this.value, [key]: val };

      if (!configuration.enable_sp_initiated_login) {
        configuration.disable_password_requirement = false;
        configuration.force_sso_authentication = false;
      }

      if (!configuration.enable_signup) {
        configuration.signup_url = '';
      }

      this.$emit('input', configuration);
    },
  },
};
</script>
