import { render, staticRenderFns } from "./PartnerConfiguration.vue?vue&type=template&id=de1c9f54"
import script from "./PartnerConfiguration.vue?vue&type=script&lang=js"
export * from "./PartnerConfiguration.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports