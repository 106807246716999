<template>
  <b-container id="saml-partner-edit" class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h3>Edit SAML Partner</h3>
        <router-link :to="{ name: 'SamlCorePartnerList' }" class="d-flex justify-content-left align-items-center">
          <feather class="back-icon" type="arrow-left"></feather>
          Back to List
        </router-link>
      </b-col>
    </b-row>
    <div v-if="loading" class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <div class="spinner spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <template v-else>
      <b-row class="mt-4">
        <b-col cols="6">
          <b-form-group
            label="Partner Key"
            label-for="partner-key">
            <b-form-input
              id="partner-key" type="text" :disabled="true"
              v-model="partner.key"/>
          </b-form-group>
          <b-form-group
            label="Partner Name"
            label-for="partner-name"
            description="Human friendly name of the Partner"
            invalid-feedback="Invalid name"
            :state="nameValid">
            <b-form-input
              id="partner-name" type="text"
              :state="nameValid"
              v-model="partner.name"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <partner-configuration v-model="partner.configuration"/>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="4" class="d-flex justify-content-left align-items-center">
          <b-button variant="primary" @click="save" :disabled="editing || !nameValid">
            <b-spinner small v-if="editing"></b-spinner>
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import Vue from 'vue';
import PartnerConfiguration from '@/views/Saml/Core/Partners/components/PartnerConfiguration.vue';
import { get, edit } from '@/api/saml/core/partners';

export default {
  name: 'SamlCorePartnerEdit',
  beforeMount() {
    this.loading = true;
    get(this.$route.params.key)
      .then(partner => {
        this.partner = partner;
      })
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to get partner: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  components: {
    PartnerConfiguration,
  },
  data() {
    return {
      loading: false,
      editing: false,
      partner: {},
    };
  },
  computed: {
    nameValid() {
      return this.partner.name ? this.partner.name.trim().length > 0 && this.partner.name.trim().length <= 75 : false;
    },
  },
  methods: {
    save() {
      this.editing = true;
      edit(this.$route.params.key, this.partner.name, this.partner.configuration)
        .then(() => {
          Vue.prototype.$noty.success('Partner saved');
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to edit partner: ${err}`);
        })
        .finally(() => {
          this.editing = false;
        });
    },
  },
};
</script>
